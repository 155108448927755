const backScrollPosMixin = {
  data () {
    return {
      scrollPosition: {},
      savedHeight: {},
      isPopstate: false,
      scrollHeight: 0,
      exceptionHeight: 774,
      availablePath: [ // 뒤로가기 적용 path
        // '/ds/diy',
        '/ds/diy2',
        '/ds/dst/SCR_DST_0015',
        '/ds/new',
        '/ds/rank',
        // '/ds/exbt',
        '/ds/bulkOrder'
      ]
    }
  },
  watch: {
    $route: {
      handler (newValue, oldValue) {
        if (this.$store.getters['page/getPopstate']) {
          this.isPopstate = true
          this.scrollHeight = this.scrollPosition[this.$route.path]?.height ?? 0
        } else {
          this.isPopstate = false
          if (this.checkPath(oldValue.path)) {
            this.$set(this.scrollPosition, oldValue.path, {})
            this.$set(this.scrollPosition[oldValue.path], 'position', window.scrollY)
            this.$set(this.scrollPosition[oldValue.path], 'height', document.documentElement.scrollHeight - this.exceptionHeight)
            sessionStorage.setItem('scrollPosition', JSON.stringify(this.scrollPosition))
          }
        }
      }
    }
  },
  updated () {
    if (this.isPopstate) {
      const savedScroll = this.scrollPosition[this.$route.path]?.position ?? 0
      if (savedScroll > 0) {
        this.$nextTick(() => {
          requestAnimationFrame(() => {
            window.scrollTo(0, savedScroll)
            this.$delete(this.scrollPosition, this.$route.path)
            sessionStorage.setItem('scrollPosition', JSON.stringify(this.scrollPosition))
          })
        })
      }
    }
  },
  methods: {
    checkPath (path) {
      return this.availablePath.some(element =>
        path === '/ds' ? true : path.includes(element)
      )
    }
  }
}

export {
  backScrollPosMixin
}
