
// import '@/assets/scss/index.scss'
import _, { throttle } from 'lodash'
import { backScrollPosMixin } from '~/mixin/common/scroll/backScrollPosMixin'
import Topbaner from '@/components/common/Topbaner.vue'
import HeaderCmp from '@/components/common/Header.vue'
import MainSkeleton from '~/components/ds/skeletonUnit/MainSkeleton.vue'
import ExbtSkeleton from '~/components/ds/skeletonUnit/ExbtSkeleton.vue'
import DiySkeleton from '~/components/ds/skeletonUnit/DiySkeleton.vue'
import Footer from '@/components/common/footer.vue'
import QuiEl from '@/components/common/QuiEl.vue'
import SCR_MBC_0115_01 from '@/pages/mb/mbc/SCR_MBC_0115_01.vue'
import LoadingBar from '~/components/common/LoadingBar'
import * as ds from '~/scriptData/ds/dsCommon.js'
import { setHolidayDeliveryFlag } from '~/scriptData/ds/dsCommon.js'

export default {
  name: 'DsMainLayout',
  components: {
    ExbtSkeleton,
    Topbaner,
    HeaderCmp,
    Footer,
    QuiEl,
    SCR_MBC_0115_01,
    LoadingBar,
    MainSkeleton,
    DiySkeleton
  },
  mixins: [backScrollPosMixin],
  provide () {
    return {
      sendPageName: this.sendPageName
    }
  },
  asyncData () {},
  fetchOnServer: false,
  data () {
    return {
      searchTerm: '',
      showTopBanner: false,
      topBannerId: '',
      bannerAccounts: [],
      openBanner: false,
      recentlyProducts: [],
      mainLogo: {}, // 메인 로고
      pageName: '',
      currentUrl: '',
      scrollY: null, // scrollTop
      headerSm: false, // 헤더 변환 여부
      headerAniEnd: false,
      stickyStatus: [ // sticky 상태 제어 (sticky 항목 최대 2개 예상)
        { position: 0, fix: false },
        { position: 0, fix: false },
        { position: 0, fix: false }
      ],
      includeUrlData: [
        'DIY',
        'ExbtListPage'
      ],

      // 공용 QuiEl 비표시 대상 페이지
      quiElExceptUrl: [
        '/ug/ugm/SCR_UGM_0001',
        '/ug/ugl/SCR_UGL_0001',
        '/ug/ugd/SCR_UGD_0007',
        '/ug/ugd/SCR_UGD_0014',
        '/ug/ugd/SCR_UGD_0019'
      ],
      quickOn: false,
      overflowObserver: null,
      scrollLoca: 0,
      showLoadingbar: false,
      isLoading: true,
      isNotAppliedSkeletonPage: false, // 홈메인, diy, 기획전만 스켈레톤 적용되어있음
      isCompleteDsMount: false,
      isCompleteExbtMount: false,
      isCompleteDiyMount: false,
    }
  },
  fetch () {
    this.loadMainLogo()
  },
  computed: {
    getMinHeight () {
      if (this.$store.getters['page/getPopstate'] && this.scrollHeight > 0 && this.scrollHeight !== undefined) {
        return this.scrollHeight + 'px'
      } else if (this.$route.path.includes('/spexhbt')) {
        return 750
      } else { return '' }
    },
    setLayoutKey () {
      return this.$route.path.includes('/ds') ? this.$route.fullPath : 'defaultPage'
    }
  },
  watch: {
    $route: {
      handler (value) {
        setHolidayDeliveryFlag(value.path, this.$store, this.$api)

        console.log('레이아웃에서 라우트 변경감지', this.$route)
        this.currentUrl = this.$route.path
        this.showLoadingbar = !this.currentUrl.includes('ds') && !this.currentUrl.includes('/pd/pdr/SCR_PDR_0001')
        this.handleTopBanner()
        if (sessionStorage.getItem('filterCheck') !== '' && sessionStorage.getItem('filterCheck') !== sessionStorage.getItem('preFilterCheck')) {
          sessionStorage.setItem('preFilterCheck', sessionStorage.getItem('filterCheck'))
        }
        if (this.$nuxt.context.from.path.includes('/ds/recmd/C206') || this.$nuxt.context.from.path.includes('/ds/dsBrand/C212')) {
          sessionStorage.setItem('filterCheck', sessionStorage.getItem('preFilterCheck'))
        }
        const scrollPos = this.$route.meta.scrollPos
        console.log('메타에 담겨서 넘어온 scrollPos', scrollPos)
        if (scrollPos) {
          this.$nextTick(() => {
            window.scrollTo({ top: scrollPos.y, left: 0 })
          })
        } else {
          this.$nextTick(() => {
            window.scrollTo({ top: 0, left: 0 })
          })
        }

        this.initCompleteValue()

        // params 에 openTransfPopup 이 true 라면 베송지 설정을 연다
        /*
        if (this.$route.params.openTransfPopup) {
          console.log('배송지 설정 팝업 바로오픈')
          this.$nextTick(() => {
            this.openTransf(true)
          })
        }
        */
      }
    }
  },
  created () {
    setHolidayDeliveryFlag(this.$route.path, this.$store, this.$api)
    this.$nuxt.$on('eb-scroll-top', this.goScrollTop)
    this.$nuxt.$on('changeHeaderSearchInput', this.changeHeaderSearchInput)
    this.$nuxt.$on('openTransfPopup', this.openTransf)
    this.$nuxt.$on('completeDsMount', () => { this.isCompleteDsMount = true })
    this.$nuxt.$on('completeExbtMount', () => { this.isCompleteExbtMount = true })
    this.$nuxt.$on('completeDiyMount', () => { this.isCompleteDiyMount = true })
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleThrottledScroll)
    this.overflowObserver.disconnect()
    this.$nuxt.$off('eb-scroll-top')
    this.$nuxt.$off('changeHeaderSearchInput')
    this.$nuxt.$off('openTransfPopup')
    this.$nuxt.$off('completeDsMount')
    this.$nuxt.$off('completeExbtMount')
    this.$nuxt.$off('completeDiyMount')
  },
  beforeMount () {
    window.addEventListener('scroll', this.handleThrottledScroll)
    this.handleObserver()
  },
  mounted () {
    // console.log('kakao call start')
    try { window.kakaoPixel('7573203864979226263').pageView() } catch (e) {}
    // console.log('kakao call end')
    this.currentUrl = this.$route.path
    this.handleTopBanner()
    this.$nextTick(() => {
      this.isLoading = false
    })
  },
  updated () {
    /*
    console.log('UPDATED????????')
    console.log(this.$route)
    this.$nextTick(() => {
      console.log('UPDATED ---- NEXTTICK', this.$route.meta.scrollPos)
      if (this.$route.meta.scrollPos) {
        this.$refs.scrollArea.scrollTo(this.$route.meta.scrollPos)
      } else {
        this.$refs.scrollArea.scrollTo({ x: 0, y: 0 })
      }
    })
    */
  },
  unmounted () {},
  methods: {
    handleThrottledScroll: throttle(function (e) { this.scrollEvent(e) }, 50),
    handleObserver () {
      this.overflowObserver = new MutationObserver((mutations) => {
        const classValue = _.head(mutations)?.target.getAttribute(_.head(mutations).attributeName)
        this.setPopupOverflow(classValue)
      })
      this.overflowObserver.observe(_.head(document.getElementsByTagName('body')), {
        attributes: true,
        attributeFilter: ['class']
      })
    },
    setPopupOverflow (classValue) {
      const classList = classValue.split(' ')
      if (classList.includes('el-popup-parent--hidden')) {
        if (_.head(document.getElementsByTagName('html'))?.classList.contains('over-hidden')) {
          this.scrollLoca = document.body.scrollTop
        } else {
          this.scrollLoca = window.scrollY
        }
        _.head(document.getElementsByTagName('html'))?.classList.add('over-hidden')
        document.body.scrollTo(0, this.scrollLoca)
      } else {
        _.head(document.getElementsByTagName('html'))?.classList.remove('over-hidden')
        window.scrollTo(0, this.scrollLoca)
      }
    },
    async loadMainLogo () {
      const mtrlsList = await ds.getAccntList('C225', this.$api)
        .then((accountList) => {
          return ds.getMtrlsList(accountList, this.$api)
        })
      this.mainLogo = mtrlsList.length > 0 ? _.head(_.head(_.head(mtrlsList))?.details) : {}
    },
    scrollEvent () {
      // console.log('------------------------------------------------------')
      // console.log('scrollArea 동작함')
      this.$nextTick(() => {
        const quickEl = this.$refs.quickEl
        this.scrollY = Math.round(window.scrollY)
        this.stickyControl('sticky-cont')
        // 23.08.30 퀵메뉴 show/hide 추가
        if (quickEl !== undefined) {
          this.quickShow()
        }
      })
      // 23.08.30 퀵메뉴 show/hide 추가
    },
    onClickWindow (e) {
      if (e.target.closest('.search-wrap') === null) {
        this.$refs.header.searchPopup(e, 'blur')
      } else if (e.relatedTarget !== null && e.relatedTarget.classList.contains('logo')) {
        this.$refs.header.searchPopup(e, 'blur')
      }
    },
    goScrollTop () {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    },
    openTransf (isOpen) {
      this.$refs.SCR_MBC_0115_01.outerVisible = isOpen
      this.$refs.SCR_MBC_0115_01.srcComp = 'Header'
    },
    changeHeaderSearchInput (keyword) {
      this.searchTerm = keyword
    },
    sendPageName (pageName) {
      this.pageName = pageName
    },
    headerControl (topPosition = 0) { // 헤더 상태 제어
      // const headerCont = this.$refs.header.$el
      if (this.scrollY >= 0) {
        if (this.scrollY > parseInt(topPosition)) {
          this.headerSm = true
        } else {
          this.headerSm = false
        }
      }
    },
    stickyControl (itmName) { // sticky 체크
      const stickyCont = document.getElementsByClassName(itmName)
      // const stickyContTop = stickyCont.getBoundingClientRect().top - this.$refs.header.$el.clientHeight

      for (let i = 0; i < stickyCont.length; i++) {
        const stickyContTop = stickyCont[i].getBoundingClientRect().top - this.$refs.header.$el.clientHeight - 16

        if (this.stickyStatus[i] !== undefined) {
          if (stickyContTop < 0 && this.stickyStatus[i].fix === false) {
            this.stickyStatus[i].position = this.scrollY
            stickyCont[i].classList.add('top-sticky')
            stickyCont[i].parentElement.classList.add('top-sticky-wrap')
            this.stickyStatus[i].fix = true
          } else if (this.stickyStatus[i].position > this.scrollY && this.stickyStatus[i].fix === true) {
            stickyCont[i].classList.remove('top-sticky')
            stickyCont[i].parentElement.classList.remove('top-sticky-wrap')
            this.stickyStatus[i].fix = false
          }
        } else {
          return
        }
      }
    },
    // 23.08.30 퀵메뉴 show/hide 추가
    quickShow () {
      if (this.scrollY > 100) {
        // prop으로 컨트롤 하게 변경 함
        this.quickOn = true
        // item.classList.add('on')
      } else {
        // item.classList.remove('on')
        this.quickOn = false
      }
    },
    // GNB 상단 띠배너 노출 여부 추가
    async handleTopBanner () {
      const pathName = document.location.pathname.split('/')
      if (pathName.includes('ds') && pathName.length <= 2) {
        this.bannerAccounts = await ds.getAccntList('C227', this.$api).then((res) => { return res.filter((item) => { return item.aplMdiaPcomYn === 'Y' && item.exhCmptmtTyCd === '07' }) })
        const exhCmptmtId = _.head(this.bannerAccounts)?.exhCmptmtId
        this.topBannerId = exhCmptmtId + '-' + _.head(_.head(this.bannerAccounts)?.mtrls)?.exhMtrlNo
        const cookies = this.$cookiz.get('topAnimation')
        if (this.topBannerId === cookies) {
          this.showTopBanner = false
        } else {
          this.showTopBanner = true
        }
      } else {
        this.showTopBanner = false
      }
    },
    initCompleteValue () {
      this.isCompleteDsMount = false
      this.isCompleteExbtMount = false
      this.isCompleteDiyMount = false
      if (!this.$route.path === '/ds' && !this.$route.path.includes('/exbt') && !this.$route.path.includes('diy')) {
        this.isNotAppliedSkeletonPage = true
      }
    }
  }
}
